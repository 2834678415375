import React, { useEffect, useState } from "react";
import "./rule.css";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";
import { api } from "../../api/api";

const Rules = () => {
  const { currUserData } = useAuthContext();
  const [rule, setRule] = useState();
  console.log(currUserData?.token, "ffff");
  const rules = async () => {
    let res = await api(`scripts/viewallrules`, {}, "get", {}, "");

    if (res && res.success) {
      console.log(res[0].rules, "res ll");
      setRule(res[0].rules);
    } else {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    rules();
  }, []);

  return (
    <div className="rules mt_50 mb_50">
      <div className="container">
        <div className="rule_box">
          {rule?.map((items) => {
            return (
              <>
                <h2 className="main_heading mb-3">{items.heading}</h2>
                {items?.para?.map((item, index) => {
                  return (
                    <>
                      <p class="main_text mb_30">
                        {index + 1}.{item}
                      </p>
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Rules;
