import React, { useEffect, useState } from "react";
import { api } from "../../api/api";
import Baby from "../../assets/image/baby.png";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";

import "./vote.css";

const VoteChecking = () => {
  const { currUserData } = useAuthContext();
  const [participateId, setParticipateId] = useState();
  const [data, setData] = useState();
  const getParticipate = async () => {
    const payload = {
      babyCode: participateId.toUpperCase(),
    };
    let res = await api(
      `participate/getparticipant`,
      payload,
      "post",
      currUserData.token,
      ""
    );

    if (res && res.success) {
      console.log(res, "swssss");
      setData(res);
    } else {
      setData();
    }
  };

  return (
    <div className="vote_checking mt_50 mb_50">
      <div className="container">
        <div className="vote_box box_bg mb-5">
          <h1 className="main_heading">Baby Code</h1>

          <div className="input-group mb-5 mt-5">
            <input
              type="text"
              className=" custom_input"
              placeholder="Enter Baby Code"
              onChange={(e) => setParticipateId(e.target.value)}
            />
            <span className="btn_linear" onClick={() => getParticipate()}>
              Submit
            </span>
          </div>

          {console.log(data, "ddddd")}
          {data ? (
            <>
              <div className="result">
                <h1 className="main_heading pb-5">Your Baby Photo</h1>
                <div className="img_box mb-3">
                  <img src={Baby} alt="" />

                  <h6>{data?.name}</h6>
                </div>
                <p>Votes : {data?.votes}</p>
                <button className="btn_boder mb-5">
                  View All Participants Votes
                </button>

                <p className="main_text">
                  Note : Votes will be closed on this week
                  <span
                    style={{
                      color: "rgba(233, 87, 192, 1)",
                      paddingLeft: "5px",
                    }}
                  >
                    16-06-2024 ,11:59PM
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <h1 className="main_heading pb-2">Sorry!</h1>
                <p className="main_text">
                  Sorry! Your baby is not selected in this week's contest
                </p>
              </div>
            </>
          )}
        </div>

        <div className="participate_box box_bg">
          <h1 className="main_heading">Want to participate again ?</h1>
          <p className="sub_text mb-5 mt-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <button className="btn_linear">Participate</button>
        </div>
      </div>
    </div>
  );
};

export default VoteChecking;
