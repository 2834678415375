import React, { useEffect, useState } from "react";
import "./winner.css";
import Toy from "../../assets/image/elephant.png";
import Baby from "../../assets/image/baby.png";
import { Link } from "react-router-dom";
import { api } from "../../api/api";

const Winners = () => {
  const [data, setData] = useState();

  const winners = async () => {
    let res = await api(`winners/getallwinners`, {}, "get", {}, "");

    if (res && res.success) {
      console.log(res, "res ssssssfffffffffffff");
      setData(res);
    } else {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    winners();
  }, []);

  return (
    <div className="winners mt_50 mb_50">
      <div className="container">
        <div className="winner_box box_bg">
          <div className="image_heading">
            <img src={Toy} alt="" />
            <h2 className="main_heading pe-5 ps-5">Winners Gallery</h2>
            <img src={Toy} alt="" />
          </div>

          <div className="checking_vote_text">
            To know the status of your baby, Please
            <Link to="/vote"> click here</Link>
          </div>

          <div className="winners_post mt-5">
            <div className="row gy-5 gx-5">
              {data &&
                data?.map((item) => {
                  return (
                    <>
                      <div className="col-md-3">
                        <div className="img_box">
                          <img src={item.winner.babyImage} alt="" />

                          <h6>{item.winner.name}</h6>
                          <p>May 1st Week</p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Winners;
