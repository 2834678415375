import React, { useEffect, useState } from "react";
import "./Participate.css";
import Toy from "../../assets/image/elephant.png";
import { Link } from "react-router-dom";
import { api } from "../../api/api";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";

const Participate = () => {
  const [participateValue, setParticipateValue] = useState({});
  const [file, setFile] = useState(null);
  const [SelectState, setSelectState] = useState("");
  const [state, setState] = useState();
  const [SelectDistrict, setSelectDistrict] = useState("");
  const [district, setDistrict] = useState();
  const { currUserData, doLogin } = useAuthContext();

  const handleChange = (e) => {
    setParticipateValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleState = (e) => {
    setSelectState(e.target.value);
  };

  const handleDistrict = (e) => {
    setSelectDistrict(e.target.value);
  };

  console.log({ file }, "filefilefiled dfdffd filefile");

  const ParticipateForm = async () => {
    const payload = {
      name: participateValue.babyName,
      babyAge: participateValue.BabyAge,
      email: participateValue.EmailID,
      state: SelectState,
      district: SelectDistrict,
      babyImage: file,
    };

    let res = await api(
      `participate/addparticipant`,
      payload,
      "postMultipart",
      currUserData.token,
      "Participation successful"
    );

    if (res && res.success) {
    } else {
      // setIsLoading(false);
    }
  };

  const getState = async () => {
    let res = await api(`state/states`, {}, "get", currUserData?.token, "");

    if (res && res.success) {
      setState(res);
    } else {
      // setIsLoading(false);
    }
  };
  const getDistrict = async () => {
    let res = await api(
      `district/districts`,
      {},
      "get",
      currUserData?.token,
      ""
    );

    if (res && res.success) {
      setDistrict(res);
    } else {
    }
  };

  useEffect(() => {
    getState();
    getDistrict();
  }, []);

  const formSubmit = (e) => {
    e.preventDefault();
    ParticipateForm();
  };

  return (
    <div className="participate mt_50 mb_50">
      <div className="container">
        <div className="form_box box_bg mb-5">
          <div className="image_heading">
            <img src={Toy} alt="" />
            <h2 className="main_heading pe-5 ps-5">
              Week of the Baby photo Contest
            </h2>
            <img src={Toy} alt="" />
          </div>

          <form onSubmit={formSubmit} className="">
            <input
              type="text"
              className="custom_input"
              placeholder="Name of the Baby"
              name="babyName"
              onChange={handleChange}
            />
            <input
              type="text"
              className="custom_input"
              placeholder="Baby Age"
              name="BabyAge"
              onChange={handleChange}
            />
            <input
              type="text"
              className="custom_input"
              placeholder="Email ID"
              name="EmailID"
              onChange={handleChange}
            />
            <input
              type="text"
              className="custom_input"
              placeholder="Phone Number (Optional)"
              name="PhoneNumber"
              onChange={handleChange}
            />
            <div className="row mb-4">
              <div className="col-md-6">
                <select
                  className="form-select custom_input"
                  aria-label="Default select example"
                  value={SelectState}
                  onChange={handleState}
                >
                  <option value="" disabled selected>
                    Select State
                  </option>
                  {console.log(state, "stat statestatestatee")}
                  {state?.map((item) => {
                    return (
                      <>
                        <option>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-6">
                <select
                  className="form-select custom_input"
                  aria-label="Default select example"
                  value={SelectDistrict}
                  onChange={handleDistrict}
                >
                  <option value="" disabled selected>
                    Select District
                  </option>

                  {district?.map((item) => {
                    return (
                      <>
                        <option>{item.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            <label htmlFor="upload" className="upload btn_boder">
              <i className="fas fa-upload pe-3"></i> Upload
              <input
                type="file"
                id="upload"
                name="image"
                onChange={handleFileChange}
              />
            </label>
            <p className="mt-2">{file?.name}</p>
            <button
              type="submit"
              className="btn_linear d-block me-auto ms-auto mt-5"
            >
              Submit
            </button>
          </form>
        </div>

        <div className="privacy_box box_bg">
          <p className="main_text">
            By participating in contest, you are agreeing{" "}
            <Link to="/vote" className="underLine">
              Privacy Policy & Terms & Conditions
            </Link>{" "}
            and{" "}
            <Link to="/vote" className="underLine">
              Contest Rules.
            </Link>
            <br />
            There is no fee for participation & voting, if anyone demand for any
            kind of fee then don't pay fee.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Participate;
