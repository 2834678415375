import React, { useEffect, useState } from "react";
import "./home.css";
import CoinWallet from "../../assets/image/CoinWallet.png";
import AddNew from "../../assets/image/AddNew.png";
import NFT from "../../assets/image/NFT.png";
import Camera from "../../assets/image/camera.png";
import Sell from "../../assets/image/Sell.png";
import Baby from "../../assets/image/baby.png";
import King from "../../assets/image/king.png";
import gallery from "../../assets/image/gallery_group.png";
import line from "../../assets/image/line.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeroBanner from "../../assets/image/hero-banner.png";
import Eye from "../../assets/image/eye.png";
import { api } from "../../api/api";

const Home = () => {
  const [contests, setContests] = useState();
  const [email, setEmail] = useState();
  console.log(email, "ss");
  const Contest = async () => {
    let res = await api(`home/gethomeCount`, {}, "get", {}, "");
    console.log(res, "res");
    if (res && res.success) {
      setContests(res);
    } else {
      // setIsLoading(false);
    }
  };
  const createSubscription = async () => {
    const payload = {
      email: email,
    };
    let res = await api(
      `home/createsubscription`,
      payload,
      "post",
      {},
      "Subscription Added Successfully"
    );
    console.log(res, "res");
    if (res && res.success) {
      setContests(res);
    } else {
      // setIsLoading(false);
    }
  };
  const formSubmit = (e) => {
    e.preventDefault();
    createSubscription();
    setEmail("");
  };
  console.log(contests, "contests");
  useEffect(() => {
    Contest();
  }, []);

  return (
    <>
      <section className="hero-sections">
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <div className="content">
                <h1>Week of the Baby photo Contest</h1>
                <Link className="btn_linear" to="/Participate">
                  Participate
                </Link>
                <div className="mt-4">
                  <h4>
                    <img src={Eye} /> Quick Stat About the baby photo Contest
                  </h4>
                  <ul>
                    <li>
                      <span>Winners </span>
                      <h4>{500 + contests?.winners} +</h4>
                    </li>
                    <li>
                      <span>Contest</span>
                      <h4>{500 + contests?.participants} +</h4>
                    </li>
                    <li>
                      <span>Happy Entrants</span>
                      <h4>{500 + contests?.HappyEntrans} +</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="hero-img">
                <img src={HeroBanner} alt="Hero Banner" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* contest */}
      <div className="contest">
        <div className="container">
          <h1 className="main_heading text-center mb-5 pb-5">
            Process of this Contest
          </h1>
          <div className="contest_box">
            <div className="row gy-5">
              <div className="col-md-6">
                <div className="context_inner">
                  <div className="over_img">
                    <img src={CoinWallet} alt="" />
                  </div>
                  <div className="content">
                    <h6>1. Registration</h6>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="context_inner">
                  <div className="over_img">
                    <img src={AddNew} alt="" />
                  </div>
                  <div className="content">
                    <h6>1. Getting Votes</h6>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="context_inner">
                  <div className="over_img">
                    <img src={NFT} alt="" />
                  </div>
                  <div className="content">
                    <h6>1. Get Connected</h6>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="context_inner">
                  <div className="over_img">
                    <img src={Sell} alt="" />
                  </div>

                  <div className="content">
                    <h6>1. Results & Prizes</h6>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* camera */}

      <div className="camera">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="camera_img">
                <img src={Camera} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h1 className="main_heading mb-4">Lights, Camera, Click!</h1>
                <p className="main_text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* week winner */}

      <div className="week_winner">
        <div className="container">
          <h1 className="main_heading text-center mb-5">
            Week of the Baby Winner
          </h1>

          <div className="week_winner_img">
            <div className="king_img">
              <img src={King} alt="" />
            </div>
            <div className="img_box">
              <img src={Baby} alt="" />
              <h6>Hanvik</h6>
            </div>
          </div>
        </div>
      </div>

      {/* winners gallery */}

      <div className="winners_gallery">
        <div className="container">
          <h1 className="main_heading text-center mb-5">Winners Gallery</h1>
          <div className="winners_gallery_inner">
            <img src={gallery} alt="" />
          </div>
          <div className="text-center mt-5">
            <button className="btn_boder">View All</button>
          </div>
        </div>
      </div>

      {/* Subscribe */}

      <div className="subscribe">
        <div className="container">
          <div className="subscribe_content">
            <h1 className="main_heading text-center mb-5">
              Subscribe for Event Updates
            </h1>
            <p className="main_text text-center mb-5">
              Set a secondary sales fee and add social links, a description,
              profile and banner images, and a description.
            </p>
            <form onSubmit={formSubmit} className="w-100">
              <input
                type="text"
                value={email}
                className="custom_input"
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
              />

              <button
                type="submit"
                className="btn_linear d-block me-auto ms-auto mt-5"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* states */}
      <div className="state">
        <div className="container">
          <div className="state_content">
            <h1 className="main_heading text-center mb-5">
              Participants from different states
            </h1>

            <ul>
              <li>Andhra Pradesh (243)</li>
              <li>Arunachal Pradesh (145)</li>
              <li>Chhattisgarh (123)</li>
              <li>Delhi (87)</li>
              <li>Gujarat (438)</li>
              <li>Karnataka (537)</li>
              <li>Tamil Nadu (4)</li>
              <li>Kerala (87)</li>
              <li>Maharashtra (23)</li>
              <li>Punjab (456)</li>
              <li>Kanyakumari (765)</li>
              <li>Goa (567)</li>
              <li>Uttar Pradesh (75)</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
