import React, { useEffect, useState } from "react";
import "./allParticipants.css";
import { api } from "../../api/api";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";

const AllParticipantes = () => {
  const { currUserData } = useAuthContext();
  const [popupShow, setPopupShow] = useState(false);
  const [allParticipant, setAllParticipant] = useState();
  const [selectState, setSelectState] = useState("");
  const [selectDistrict, setSelectDistrict] = useState("");

  const handleState = (e) => {
    setSelectState(e.target.value);
  };

  const handleDistrict = (e) => {
    setSelectDistrict(e.target.value);
  };

  const getAllparticipants = async () => {
    const payload = {
      state: selectState,
      district: selectDistrict,
      name: "",
    };

    let res = await api(
      `participate/getAllparticipants`,
      payload,
      "post",
      currUserData?.token,
      ""
    );

    if (res && res.success) {
      setAllParticipant(res);
      console.log(res, "res");
    } else {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllparticipants();
  }, [selectDistrict, selectState]);

  return (
    <div className="allParticipants mt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="box_bg participants_list">
              <h1 className="main_heading text-center mb-4">
                Participants votes
              </h1>

              <div className="search_input mb-4 ">
                <input
                  type="text"
                  className="custom_input"
                  placeholder="Search with baby name"
                />
                <i class="fas fa-search"></i>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="select_box">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="state"
                      onChange={handleState}
                    >
                      <option value="" selected disabled>
                        Select State
                      </option>
                      <option value="Rajasthan">Rajasthan</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="select_box">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="District"
                      onChange={handleDistrict}
                    >
                      <option value="" selected disabled>
                        Select District
                      </option>
                      <option value="Jaipur">Jaipur</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="main_text text-center pb-3">
                    1. Hanvik : 456 votes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="box_bg  total_participants">
              <h1 className="main_heading text-center">Participants votes</h1>

              <div className="mb-3">
                <h6>June</h6>
                <p>
                  2 nd Week (03-06-2024 to 09-06-2024) :{" "}
                  <span style={{ color: "rgba(233, 87, 192, 1)" }}>
                    Going On
                  </span>
                </p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
              </div>

              <div className="mb-3">
                <h6>May</h6>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
              </div>
              <div className="mb-3">
                <h6>May</h6>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
                <p>1 st Week (03-06-2024 to 09-06-2024) : 3456 Participants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllParticipantes;
