import React from "react";
import "./congretulation.css";
import Baby from "../../assets/image/baby.png";

const Congretulation = () => {
  return (
    <div className="congretulation mt_50 mb_50">
      <div className="container">
        <div className="congretulation_box box_bg mb-5">
          <h1 className="main_heading">Congratulations!</h1>
          <div className="sub_text mb-5 mt-5">
            Congratulations! Your baby has been entered into this contest
            successfully. To know the further details use this{" "}
            <span className="underLine">Baby code 0546</span>
          </div>

          <h1 className="sub_heading mb-3">Your Baby Photo</h1>

          <div className="img_box">
            <img src={Baby} alt="" />

            <h6>Hanvik</h6>
          </div>
        </div>

        <div className="congretulation_box box_bg">
          <p className="sub_text">
            Note : Winners will be announced on the last day of this week.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Congretulation;
