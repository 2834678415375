import React from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";

function Header() {
  const { currUserData } = useAuthContext();
  const location = useLocation();

  return (
    <header>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <a className="navbar-brand" href="#">
            LOGO
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  // className="navbar_link"
                  to="/"
                  className={`${
                    location.pathname === "/" ? "active" : ""
                  } navbar_link`}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/rules" ? "active" : ""
                  } navbar_link`}
                  to="/rules"
                >
                  Rules
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/winners" ? "active" : ""
                  } navbar_link`}
                  to="/winners"
                >
                  Winners
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/terms" ? "active" : ""
                  } navbar_link`}
                  to="/terms"
                >
                  Terms & Co.
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/allParticipants" ? "active" : ""
                  } navbar_link`}
                  to="/allParticipants"
                >
                  All Participants
                </Link>
              </li>
            </ul>
          </div>

          {/* {currUserData?.success === false ? (
            <>
              <Link className="btn_boder me-5" to="/login">
                Login
              </Link>
            </>
          ) : (
            ""
          )} */}
          <Link className="btn_boder me-5" to="/login">
            Login
          </Link>
          <Link className="btn_linear" to="/Participate">
            Participate
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
