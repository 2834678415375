import React, { useEffect, useState } from "react";
import "./terms.css";
import { api } from "../../api/api";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";

const Terms = () => {
  const { currUserData } = useAuthContext();
  const [term, setTerm] = useState();

  const terms = async () => {
    let res = await api(`scripts/viewTermsAndConditions`, {}, "get", {}, "");

    if (res && res.success) {
      setTerm(res[0].termsAndConditions);
    } else {
      // setIsLoading(false);
    }
  };
  console.log(term, "term");
  useEffect(() => {
    terms();
  }, []);

  return (
    <div className="terms mt_50 mb_50">
      <div className="container">
        <div className="term_box">
          {term?.map((items) => {
            return (
              <>
                <h2 className="main_heading mb_30">{items.heading}</h2>
                {items?.para.map((item, index) => {
                  return (
                    <>
                      <p className="main_text mb-3">
                        {index + 1}.{item}
                      </p>
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Terms;
