import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/fontawesome/css/fontawesome.min.css";
import "./assets/fontawesome/css/all.min.css";
import "react-phone-input-2/lib/style.css";

import "./App.css";
import Routes from "./router/Routes";
import AuthContextAPI from "./ContextAPI/AuthContextAPI";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <AuthContextAPI>
        <ToastContainer position="top-right" theme="colored" />
        <Routes />
      </AuthContextAPI>
    </>
  );
}

export default App;
