import React, { useState } from "react";
import HeroBanner from "../../assets/image/hero-banner.png";
import "./login.css";
import PhoneInput from "react-phone-input-2";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { useAuthContext } from "../../ContextAPI/AuthContextAPI";

import { toast } from "react-toastify";
const Login = () => {
  const { currUserData, doLogin } = useAuthContext();
  const [value, setValue] = useState("");
  const [otp, setOtp] = useState();
  const [show, setShow] = useState(true);
  const [email, setEmail] = useState();

  const navigate = useNavigate();
  /*-----------Login Function------------------------*/
  const userLogin = async () => {
    let payload = {
      email: email,
    };

    let res = await api(
      "otp/sendotp",
      payload,
      "postWithoutToken",
      "",
      "OTP Send successfully!"
    );

    if (res && res.success) {
      // navigate("/dashboard");
      setShow(!show);
      if (res?.data) {
        // localStorage.setItem("login", JSON.stringify(res?.data));
        // setUserType(res?.data.userType);
      }
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
    }

    console.log(res, "logi");
  };

  /*---------------VerifyOtp Function-------*/
  const VerifyOtp = async (id) => {
    const payload = {
      email: email,
      otp: otp,
    };
    let res = await api(
      `otp/verifyotp`,
      payload,
      "postWithoutToken",
      "",
      "Login successfully"
    );

    if (res && res.success) {
      navigate("/");
      doLogin(res);
    } else {
      // setIsLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="container">
        <h1 className="main_heading  mb-5 text-center">
          Week of the Baby photo Contest
        </h1>
        <div className="row">
          <div className="col-md-6">
            <div className="login_img">
              <img src={HeroBanner} alt="" />
            </div>
          </div>
          <div className="col-md-6">
            {show ? (
              <div className="login_content">
                <h1 className="main_heading mb-5">Login</h1>
                <p className="main_text mb-4">Please enter the Email address</p>
                {/* <PhoneInput
                  //  {...register("phone")}
                  country={"in"}
                  value={value}
                  onChange={(number) => setValue(number)}
                /> */}

                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="custom_input"
                  placeholder=""
                />

                <button className="btn_linear mt-5" onClick={() => userLogin()}>
                  Login
                </button>
              </div>
            ) : (
              <div className="otp_content">
                <h1 className="main_heading mb-4">Verification</h1>
                <p className="main_text mb-3">OTP has sent to xxxxx xx678</p>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  renderInput={(props) => <input {...props} />}
                />
                <p className="main_text mb-3">
                  Expires in <span style={{ fontWeight: "bold" }}>00:09</span>
                </p>

                <button className="btn_linear mt-3" onClick={() => VerifyOtp()}>
                  Verify & Continue
                </button>

                <p className="main_text mt-5">
                  Not Received?{" "}
                  <span style={{ color: "rgba(3, 118, 224, 1)" }}>
                    Resend Code
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
