import Congretulation from "../Pages/Congretulation/Congretulation";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import Participate from "../Pages/Participate/Participate";
import Rules from "../Pages/Rules/Rules";
import Terms from "../Pages/Terms/Terms";
import VoteChecking from "../Pages/VoteChecking/VoteChecking";
import Winners from "../Pages/Winners/Winners";
import AllParticipantes from "../Pages/AllParticipantes/AllParticipantes";

export const routes = [
  {
    notLazy: true,
    path: "/",
    element: Home,
    role: "private",
    name: "Home",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/rules",
    element: Rules,
    role: "private",
    name: "Rules",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/winners",
    element: Winners,
    role: "private",
    name: "Winners",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/terms",
    element: Terms,
    role: "private",
    name: "Terms",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/vote",
    element: VoteChecking,
    role: "private",
    name: "VoteChecking",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/participate",
    element: Participate,
    role: "private",
    name: "Participate",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/congretulation",
    element: Congretulation,
    role: "private",
    name: "Congretulation",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/allParticipants",
    element: AllParticipantes,
    role: "private",
    name: "allParticipants",
    subRouting: false,
  },
];
